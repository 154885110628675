import { cloneDeep, round } from "lodash";
import {
    AccountingBatchType,
    AccountingRun,
    AccountingTransaction,
    AccountingTransactionType,
    InstrumentTypeEnum,
    JournalEntry
} from "../../types.generated";
import { getTodayDateAsString } from "../../containers/reporting/SwedishFiClassificationPage";

export const isoDateToYYYYMMDD = (isoDate: string) => isoDate.substring(0, 4) + isoDate.substring(5, 7) + isoDate.substring(8);

export const numberToDecimalString = (num: number, dec: number) => round(num, dec).toFixed(dec);

export const getSie4FromAccountingRun = (accountingRun: AccountingRun, includeIngoingBalances: boolean): string => {
    // The items in SIE files are subdivided into groups
    // Items are to occur in the file in the following sequence:
    // 1 Flag item
    // 2 Identification items
    // 3 Chart of accounts information
    // 4 Balance items/Verification items

    //const QM = String.fromCharCode(92, 34);
    const QM = String.fromCharCode(34);
    const EOL = String.fromCharCode(10);
    //const RET = String.fromCharCode(13);
    const today = getTodayDateAsString();

    const usedAccountsByNumber: Record<string, boolean> = {};
    const ibByAccountNumber: Record<string, number> = {};
    const usedBatches: Record<string, boolean> = {};
    const filteredAndSortedJournalEntries: JournalEntry[] = [];
    for (const entry of accountingRun.journalEntries) {
        usedBatches[entry.batch] = true;
        for (const transaction of entry.transactions) {
            usedAccountsByNumber[transaction.tAccountNumber] = true;
        }
        // IB handled different than other batches in sie
        if (entry.batch === AccountingBatchType.IB) {
            for (const transaction of entry.transactions) {
                const accountNumber = transaction.tAccountNumber;
                if (!ibByAccountNumber[accountNumber]) {
                    ibByAccountNumber[accountNumber] = 0;
                }
                ibByAccountNumber[accountNumber] += transaction.amount;
            }
        } else {
            filteredAndSortedJournalEntries.push(entry);
        }
    }

    // Flag item which specifies whether the file has been received by the recipient.
    let sieString = "#FLAGGA 0" + EOL;
    //Which program generated the file
    sieString += "#PROGRAM " + QM + "Captor Portfolio Management" + QM + EOL;
    // Which character set was used. Until further notice, the standard only permits IBM Extended 8-bit ASCII
    sieString += "#FORMAT PC8" + EOL;
    // When and who generated the file.
    sieString += "#GEN " + isoDateToYYYYMMDD(today) + " " + QM + QM + EOL;
    // Which type of SIE format the file follows.
    sieString += "#SIETYP 4" + EOL;
    // Complete name of the exported company.
    sieString += "#FNAMN " + QM + accountingRun.client.name + QM + EOL;
    // Financial year from which the exported data is retrieved.
    sieString += "#RAR 0 " + accountingRun.accountingPeriod + "0101 " + accountingRun.accountingPeriod + "1231 " + EOL;

    // end of identification items - remove?
    sieString += EOL;

    // Chart of accounts information
    for (const account of accountingRun.clientTAccountChart.tAccounts) {
        // only used accounts
        if (usedAccountsByNumber[account.number]) {
            // account information
            sieString += "#KONTO " + account.number + " " + QM + account.description + QM + EOL;
        }
    }

    // end of accounts information - remove?
    sieString += EOL;

    // Sort by effectiveDate then id to get consistent sorting...
    filteredAndSortedJournalEntries.sort((a, b) =>
        a.effectiveDate > b.effectiveDate ? 1 : b.effectiveDate > a.effectiveDate ? -1 : a._id > b._id ? 1 : b._id > a._id ? -1 : 0
    );

    // IB items
    if (includeIngoingBalances) {
        const ibAccountNumbers = cloneDeep(Object.keys(ibByAccountNumber));
        ibAccountNumbers.sort();
        for (const ibAccountNumber of ibAccountNumbers) {
            const roundedAmount = numberToDecimalString(ibByAccountNumber[ibAccountNumber], 2);
            // #IB year no account balance quantity
            sieString += "#IB 0 " + ibAccountNumber + " " + roundedAmount + " " + QM + QM + EOL;
        }
    }

    // end of IB - remove?
    sieString += EOL;

    // Verification items
    for (const journalEntry of filteredAndSortedJournalEntries) {
        // start with quotation mark and batch + number per PRI request
        let message = QM + journalEntry.batch + journalEntry.number + ": ";
        if (journalEntry.batch === AccountingBatchType.IB) {
            continue;
        } else if (journalEntry.batch === AccountingBatchType.BVI) {
            message += "Balance valuation ingoing " + journalEntry.transactions[0].instrument.name + QM;
        } else if (journalEntry.batch === AccountingBatchType.BVU) {
            message += "Balance valuation outgoing " + journalEntry.transactions[0].instrument.name + QM;
        } else if (journalEntry.batch === AccountingBatchType.T) {
            if (journalEntry.description) {
                message += journalEntry.description + QM;
            } else {
                // try to calculate reasonable value
                const initialCost: AccountingTransaction[] = [];
                const settlementAmount: AccountingTransaction[] = [];
                const other: AccountingTransaction[] = [];
                for (const transaction of journalEntry.transactions) {
                    if (transaction.type === AccountingTransactionType.InitialCost) {
                        if (transaction.instrument.type === InstrumentTypeEnum.Instrument) {
                            initialCost.push(transaction);
                        } else {
                            settlementAmount.push(transaction);
                        }
                    } else {
                        other.push(transaction);
                    }
                }
                let tradeMessage = "";
                if (initialCost.length > 0) {
                    // trade
                    if (settlementAmount.length > 0 || initialCost[0].amount === 0) {
                        if (initialCost[0].quantity > 0) {
                            tradeMessage = "Buy: " + initialCost[0].quantity.toString() + " " + initialCost[0].instrument.name;
                        } else {
                            tradeMessage = "Sell: " + (-initialCost[0].quantity).toString() + " " + initialCost[0].instrument.name;
                        }
                    } else if (other.length > 0) {
                        tradeMessage = other[0].type + " " + initialCost[0].instrument.name;
                    } else {
                        throw Error("unknown combination of transactions");
                    }
                } else if (settlementAmount.length === 2) {
                    if (settlementAmount[0].amount < 0) {
                        tradeMessage =
                            "Transfer from " + settlementAmount[0].instrument.name + " to " + settlementAmount[1].instrument.name;
                    } else {
                        tradeMessage =
                            "Transfer from " + settlementAmount[1].instrument.name + " to " + settlementAmount[0].instrument.name;
                    }
                }
                if (tradeMessage === "") {
                    throw Error("no trade message could be calculated");
                }
                message += tradeMessage + QM;
            }
        } else {
            throw Error("Batch" + journalEntry.batch + "not implemented for SIE");
        }
        // Verification item
        // #VER series verno verdate vertext regdate sign
        // series and verification number
        sieString += "#VER " + journalEntry.batch + " " + journalEntry.number.toString() + " ";
        // date and verification text - quotation marks included in message
        sieString += isoDateToYYYYMMDD(journalEntry.effectiveDate) + " " + message + " ";
        // reg date and sign empty
        sieString += QM + QM + " " + QM + QM + EOL;
        // transactions
        sieString += "{" + EOL;

        for (const transaction of journalEntry.transactions) {
            // #TRANS account no {object list} amount transdate transtext quantity sign
            let quantity = " " + QM + QM + " ";
            if (transaction.instrument.type === InstrumentTypeEnum.Instrument && (transaction.quantity > 0 || transaction.quantity < 0)) {
                quantity = " " + transaction.quantity.toString() + " ";
                if (transaction.instrument && (transaction.instrument.quantityDecimals || transaction.instrument.quantityDecimals === 0)) {
                    quantity = " " + transaction.quantity.toString() + " ";
                }
            }
            // account number and amount
            sieString += "#TRANS " + transaction.tAccountNumber + " {} " + numberToDecimalString(transaction.amount, 2) + " ";
            // transaction date and text  do not need to be specified.
            sieString += QM + QM + " " + QM + QM;
            // add quantity if not null or zero
            sieString += quantity;
            // sign not needed
            sieString += QM + QM + EOL;
        }
        // close transactions
        sieString += "}" + EOL;
    }

    return sieString;
};
