import React, { useState, useEffect } from "react";
import { gql, useQuery } from "urql";
import { saveAs } from "file-saver";
import { Button } from "react-bootstrap";

import { getSie4FromAccountingRun } from "../../common/sie/sieFunctions";
import { AccountingRun } from "../../types.generated";

const getAccountingRun = gql`
    query getAccountingRun($_id: GraphQLObjectId!) {
        accountingRun(_id: $_id) {
            accountingPeriod
            number
            type
            endDate
            status
            client {
                name
                dashName
                accountingCompanyType
                accountingFrequency
            }
            journalEntries {
                _id
                batch
                number
                effectiveDate
                description
                portfolioTransactionId
                portfolioTransaction {
                    type
                }
                transactions {
                    amount
                    quantity
                    tAccountNumber
                    type
                    instrumentId
                    instrument {
                        type
                        name
                        quantityDecimals
                    }
                }
            }
            clientTAccountChart {
                tAccounts {
                    number
                    type
                    sruCode
                    description
                }
            }
        }
    }
`;

export function DownloadSieFileButton({ accountingRunId }: { accountingRunId: string }): React.ReactElement {
    const [download, setDownload] = useState(false);
    const [conversionError, setConversionError] = useState<string | null>(null);
    const [{ data, fetching, error }] = useQuery({
        query: getAccountingRun,
        variables: { _id: accountingRunId },
        requestPolicy: "network-only",
        pause: !download
    });

    useEffect(() => {
        if (data && download) {
            try {
                const accountingRun = data.accountingRun as AccountingRun;
                const sieString = getSie4FromAccountingRun(accountingRun, true);
                const bytes = new Uint8Array(sieString.length);
                for (let i = 0; i < sieString.length; i++) {
                    bytes[i] = sieString.charCodeAt(i) & 0xff;
                }

                const blob = new Blob([bytes], { type: "text/plain;charset=ISO-8859-1" });
                const filename = `${accountingRun.client.dashName}-${accountingRun.endDate}.si`;
                saveAs(blob, filename);
                setDownload(false);
            } catch (error) {
                setConversionError(error.message);
                setDownload(false);
            }
        }
    }, [data, download]);

    if (fetching) return <div>Loading...</div>;

    if (error) return <div>{error.message}</div>;

    if (conversionError) return <div>{conversionError}</div>;

    return (
        <Button
            variant="link"
            size="sm"
            onClick={() => {
                setDownload(true);
            }}
        >
            file
        </Button>
    );
}
