import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export function useQueryState<T>(key: string, defaultValue: T): [T, (newValue: T) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = (searchParams.get(key) as T) ?? defaultValue;

    const setValue = useCallback(
        (newValue) => {
            setSearchParams(
                (prevParams) => {
                    const params = new URLSearchParams(prevParams);
                    if (newValue === null || newValue === undefined || newValue === "") {
                        params.delete(key);
                    } else {
                        params.set(key, newValue);
                    }
                    return params;
                },
                { replace: true }
            );
        },
        [key, setSearchParams]
    );

    return [value, setValue];
}
